import AbsencePage from 'routes/AbsencePage/AbsencePage';
import ColleaguesPage from 'routes/ColleaguesPage/ColleaguesPageContainer';
import Dashboard from 'routes/Dashboard/Dashboard';
import LoginPage from 'routes/LoginPage/LoginPage';
import Page404 from 'routes/Page404/Page404';
import StatusAndWorktime from 'routes/StatusAndWorktime/StatusAndWorktime';
import WikiSearch from 'routes/WikiSearch/WikiSearch';
import WikiDetailsPage from 'routes/WikiDetailsPage/WikiDetailsPage';
import WikiMainPage from 'routes/WikiMainPage/WikiMainPage';
import WikiChangeLog from 'routes/WikiChangeLog/WikiChangeLog';
import Profile from 'routes/Profile/Profile';

export default {
  AbsencePage,
  // BonusPage,
  ColleaguesPage,
  Dashboard,
  LoginPage,
  // MessagePage,
  // OfficeMap,
  Page404,
  StatusAndWorktime,
  WikiDetailsPage,
  WikiMainPage,
  WikiChangeLog,
  Profile,
  WikiSearch,
};
