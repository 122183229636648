import React from 'react';
import WikiSearchInput from 'components/Wiki/WikiSearchInput';
import WikiAutoCompleteInput from 'components/Wiki/WikiAutoCompleteInput';
import './wiki-search.scss';

const WikiSearchPanel = () => (
  <div className="wiki-search-container">
    <WikiSearchInput placeholderText="Search in text" />
    <WikiAutoCompleteInput placeholderText="Search in tags" />
  </div>
);

export default WikiSearchPanel;
