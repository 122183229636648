import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import ROUTES from 'routes';
import AppLayout from 'layouts/AppLayout/AppLayout';
import LoginLayout from 'layouts/LoginLayout/LoginLayout';
import requireAuth from 'components/App/Auth';
import './App.scss';
import { connect } from 'react-redux';
import { setCognitoCode } from '../../actions/userActions';


const AppRouteWithLayout = ({ layout, component: Component, ...props }) => (
  React.createElement(layout, props, <Route {...props} component={requireAuth(Component)} />)
);

AppRouteWithLayout.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
};

export const PublicRoute = ({ layout, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (React.createElement(layout, props,
      <Component {...props} />))}
  />
);

PublicRoute.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.shape({}).isRequired,
};

const App = ({ dispatchSetCognitoCode: setCode }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  if (code) {
    setCode(code);
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <AppRouteWithLayout exact path="/dashboard" layout={AppLayout} component={ROUTES.Dashboard} />
      <AppRouteWithLayout exact path="/profile" layout={AppLayout} component={ROUTES.Profile} />
      <AppRouteWithLayout exact path="/status-and-worktime" layout={AppLayout} component={ROUTES.StatusAndWorktime} />
      {/* <AppRouteWithLayout exact path="/bonus" layout={AppLayout} component={ROUTES.BonusPage} /> */}
      {/* <AppRouteWithLayout exact path="/messages" layout={AppLayout} component={ROUTES.MessagePage} /> */}
      <AppRouteWithLayout exact path="/absence" layout={AppLayout} component={ROUTES.AbsencePage} />
      <AppRouteWithLayout exact path="/colleagues" layout={AppLayout} component={ROUTES.ColleaguesPage} />
      {/* <AppRouteWithLayout exact path="/office-map" layout={AppLayout} component={ROUTES.OfficeMap} /> */}
      <AppRouteWithLayout exact path="/wiki/searchresults" layout={AppLayout} component={ROUTES.WikiSearch} />
      <AppRouteWithLayout exact path="/wiki" layout={AppLayout} component={ROUTES.WikiMainPage} />
      <AppRouteWithLayout exact path="/wiki/change-log" layout={AppLayout} component={ROUTES.WikiChangeLog} />
      <AppRouteWithLayout exact path="/wiki/:category/:id" layout={AppLayout} component={ROUTES.WikiDetailsPage} />
      <PublicRoute exact path="/login" layout={LoginLayout} component={ROUTES.LoginPage} />
      <Route component={ROUTES.Page404} />
    </Switch>
  );
};

App.propTypes = {
  dispatchSetCognitoCode: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchSetCognitoCode: setCognitoCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
